import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import MarkdownWrapper from '../../../../components/MarkdownWrapper';
import Layout from '../../../../components/Layout';
import Accordion from '../../../../components/Accordion';
export const _frontmatter = {
  "title": "AWS CDK and CloudFormation",
  "path": "/knowledge/tech/aws",
  "date": "2023-01-22T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout title={props.pageContext.frontmatter.title} location={props.path} mdxType="Layout">
      <MarkdownWrapper mdxType="MarkdownWrapper">
        <h1 {...{
          "id": "aws--cdk-and-cloudformation",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h1" {...{
            "href": "#aws--cdk-and-cloudformation",
            "aria-label": "aws  cdk and cloudformation permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`AWS — CDK and CloudFormation`}</h1>
        <h2 {...{
          "id": "defining-aws-infra-with-cdk-and-deploying-it",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#defining-aws-infra-with-cdk-and-deploying-it",
            "aria-label": "defining aws infra with cdk and deploying it permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Defining AWS infra with CDK and deploying it`}</h2>
        <blockquote>
          <p parentName="blockquote">{`Stack: collection of AWS services (constructs)`}</p>
        </blockquote>
        <blockquote>
          <p parentName="blockquote">{`Construct: AWS service`}</p>
        </blockquote>
        <p><strong parentName="p">{`Goal:`}</strong>{` create an API gateway with a resource named `}<inlineCode parentName="p">{`pikachu`}</inlineCode>{` and an HTTP backend integration that returns Pikachu data from PokeAPI.`}</p>
        <p>{`We'll define a `}<em parentName="p">{`Stack`}</em>{` that contains an API gateway `}<em parentName="p">{`Construct`}</em>{` and deploy it to our AWS account. The API Gateway will have a route with an HTTP backend integration that once hit, will send back Pikachu data from PokeAPI.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-ts"
          }}>{`import { CorsHttpMethod, HttpMethod } from '@aws-cdk/aws-apigatewayv2-alpha'
import { Stack, StackProps, CfnOutput } from 'aws-cdk-lib'
import { HttpUrlIntegration } from '@aws-cdk/aws-apigatewayv2-integrations-alpha'
import { Construct } from 'constructs'
import * as apigwv2 from '@aws-cdk/aws-apigatewayv2-alpha'

export class CdkStack extends Stack {
  // All the stacks have the same props
  // It's possible to have multiple stacks
  // A stack can have multiple constructs
  // https://docs.aws.amazon.com/cdk/v2/guide/getting_started.html#getting_started_concepts
  constructor(scope: Construct, id: string, props?: StackProps) {
    super(scope, id, props)

    // HTTP backend integration
    const getPokemonIntegration = new HttpUrlIntegration(
      'GetPokemonIntegration',
      'https://pokeapi.co/api/v2/pokemon-form/pikachu'
    )

    // Construct new HTTP API
    const httpApi = new apigwv2.HttpApi(this, 'pikachu-api', {
      description: 'Pikachu API',
      corsPreflight: {
        allowHeaders: [
          'Content-Type',
          'X-Amz-Date',
          'Authorization',
          'X-Api-Key',
        ],
        allowMethods: [
          CorsHttpMethod.OPTIONS,
          CorsHttpMethod.GET,
          CorsHttpMethod.POST,
          CorsHttpMethod.PUT,
          CorsHttpMethod.PATCH,
          CorsHttpMethod.DELETE,
        ],
        allowCredentials: true,
        allowOrigins: ['http://localhost:3000'],
      },
    })

    // Add route/resource and its integration to HTTP API
    httpApi.addRoutes({
      path: '/pikachu',
      methods: [HttpMethod.GET],
      integration: getPokemonIntegration,
    })

    // Add stage to HTTP API
    new apigwv2.HttpStage(this, 'Stage', {
      httpApi,
      stageName: 'prod',
    })

    // Once it gets deployed, output the API URL
    new CfnOutput(this, 'apiUrl', {
      value: httpApi.url!,
    })
  }
}
`}</code></pre>
        <p>{`Deploying it can be done with a single command `}<inlineCode parentName="p">{`npx cdk deploy`}</inlineCode>{`. If we went to our AWS account, we should find the new API gateway.`}</p>
        <h2 {...{
          "id": "turn-our-stack-into-a-cloudformation-template",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#turn-our-stack-into-a-cloudformation-template",
            "aria-label": "turn our stack into a cloudformation template permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Turn our stack into a CloudFormation template`}</h2>
        <p>{`We simply run the command `}<inlineCode parentName="p">{`npx cdk synth`}</inlineCode>{` and it prints the CloudFormation template for the stack. In our case, it'll print:`}</p>
        <Accordion summaryText="CloudFormation template" mdxType="Accordion">
          <pre><code parentName="pre" {...{
              "className": "language-yaml"
            }}>{`Resources:
  pikachuapiAE61503F:
    Type: AWS::ApiGatewayV2::Api
    Properties:
      CorsConfiguration:
        AllowCredentials: true
        AllowHeaders:
          - Content-Type
          - X-Amz-Date
          - Authorization
          - X-Api-Key
        AllowMethods:
          - OPTIONS
          - GET
          - POST
          - PUT
          - PATCH
          - DELETE
        AllowOrigins:
          - http://localhost:3000
      Description: Pikachu API
      Name: pikachu-api
      ProtocolType: HTTP
    Metadata:
      aws:cdk:path: CdkStack/pikachu-api/Resource
  pikachuapiDefaultStageB24BF708:
    Type: AWS::ApiGatewayV2::Stage
    Properties:
      ApiId:
        Ref: pikachuapiAE61503F
      StageName: $default
      AutoDeploy: true
    Metadata:
      aws:cdk:path: CdkStack/pikachu-api/DefaultStage/Resource
  pikachuapiGETpikachuGetPokemonIntegrationF4A0D085:
    Type: AWS::ApiGatewayV2::Integration
    Properties:
      ApiId:
        Ref: pikachuapiAE61503F
      IntegrationType: HTTP_PROXY
      IntegrationMethod: ANY
      IntegrationUri: https://pokeapi.co/api/v2/pokemon-form/pikachu
      PayloadFormatVersion: '1.0'
    Metadata:
      aws:cdk:path: CdkStack/pikachu-api/GET--pikachu/GetPokemonIntegration/Resource
  pikachuapiGETpikachu4262A8E7:
    Type: AWS::ApiGatewayV2::Route
    Properties:
      ApiId:
        Ref: pikachuapiAE61503F
      RouteKey: GET /pikachu
      AuthorizationType: NONE
      Target:
        Fn::Join:
          - ''
          - - integrations/
            - Ref: pikachuapiGETpikachuGetPokemonIntegrationF4A0D085
    Metadata:
      aws:cdk:path: CdkStack/pikachu-api/GET--pikachu/Resource
  Stage0E8C2AF5:
    Type: AWS::ApiGatewayV2::Stage
    Properties:
      ApiId:
        Ref: pikachuapiAE61503F
      StageName: prod
    Metadata:
      aws:cdk:path: CdkStack/Stage/Resource
  CDKMetadata:
    Type: AWS::CDK::Metadata
    Properties:
      Analytics: v2:deflate64:H4sIAAAAAAAA/02OQQ6CMBBFz+K+LZSFcalhoysTPICpUHEA24ZOIYb07raFBav3Z/Izbwp25GzhBzFbWjc9HeDFlgeKuidh9RQGWoFyFr+pYEv5VhcDJCA0WhnDTaFsR4GgVRwr7VD6mO4OjcO0k1a7sU71UqsGYtkTTsVgPoLlh/MmzyL3xq2xXBFNFEeu5piSK6XdE94TpRvJOptN/MTycL6zAHR0CuErWbXyD0hjvU33AAAA
    Metadata:
      aws:cdk:path: CdkStack/CDKMetadata/Default
    Condition: CDKMetadataAvailable
Outputs:
  apiUrl:
    Value:
      Fn::Join:
        - ''
        - - https://
          - Ref: pikachuapiAE61503F
          - .execute-api.
          - Ref: AWS::Region
          - '.'
          - Ref: AWS::URLSuffix
          - /
Conditions:
  CDKMetadataAvailable:
    Fn::Or:
      - Fn::Or:
          - Fn::Equals:
              - Ref: AWS::Region
              - af-south-1
          - Fn::Equals:
              - Ref: AWS::Region
              - ap-east-1
          - Fn::Equals:
              - Ref: AWS::Region
              - ap-northeast-1
          - Fn::Equals:
              - Ref: AWS::Region
              - ap-northeast-2
          - Fn::Equals:
              - Ref: AWS::Region
              - ap-south-1
          - Fn::Equals:
              - Ref: AWS::Region
              - ap-southeast-1
          - Fn::Equals:
              - Ref: AWS::Region
              - ap-southeast-2
          - Fn::Equals:
              - Ref: AWS::Region
              - ca-central-1
          - Fn::Equals:
              - Ref: AWS::Region
              - cn-north-1
          - Fn::Equals:
              - Ref: AWS::Region
              - cn-northwest-1
      - Fn::Or:
          - Fn::Equals:
              - Ref: AWS::Region
              - eu-central-1
          - Fn::Equals:
              - Ref: AWS::Region
              - eu-north-1
          - Fn::Equals:
              - Ref: AWS::Region
              - eu-south-1
          - Fn::Equals:
              - Ref: AWS::Region
              - eu-west-1
          - Fn::Equals:
              - Ref: AWS::Region
              - eu-west-2
          - Fn::Equals:
              - Ref: AWS::Region
              - eu-west-3
          - Fn::Equals:
              - Ref: AWS::Region
              - me-south-1
          - Fn::Equals:
              - Ref: AWS::Region
              - sa-east-1
          - Fn::Equals:
              - Ref: AWS::Region
              - us-east-1
          - Fn::Equals:
              - Ref: AWS::Region
              - us-east-2
      - Fn::Or:
          - Fn::Equals:
              - Ref: AWS::Region
              - us-west-1
          - Fn::Equals:
              - Ref: AWS::Region
              - us-west-2
Parameters:
  BootstrapVersion:
    Type: AWS::SSM::Parameter::Value<String>
    Default: /cdk-bootstrap/hnb659fds/version
    Description: Version of the CDK Bootstrap resources in this environment, automatically retrieved from SSM Parameter Store. [cdk:skip]
Rules:
  CheckBootstrapVersion:
    Assertions:
      - Assert:
          Fn::Not:
            - Fn::Contains:
                - - '1'
                  - '2'
                  - '3'
                  - '4'
                  - '5'
                - Ref: BootstrapVersion
        AssertDescription: CDK bootstrap stack version 6 required. Please run 'cdk bootstrap' with a recent version of the CDK CLI.
`}</code></pre>
        </Accordion>
        <h2 {...{
          "id": "why-aws-cdk",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#why-aws-cdk",
            "aria-label": "why aws cdk permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Why AWS CDK`}</h2>
        <p>{`CloudFormation has been around more than CDK and it has more features and a larger user base. But, I think for those who often code, provisioning and managing our infra on AWS with code is easier than JSON or YAML.`}</p>
        <p>{`AWS CDK is for those who prefer using a code-based approach to define the infra, while AWS CloudFormation is for those who prefer using JSON or YAML or prefer to just continue using CloudFormation as they've been using it for a while.`}</p>
        <h2 {...{
          "id": "more-examples",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#more-examples",
            "aria-label": "more examples permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`More examples`}</h2>
        <h3 {...{
          "id": "lambda-function",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#lambda-function",
            "aria-label": "lambda function permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Lambda Function`}</h3>
        <p><inlineCode parentName="p">{`cdk-stack.ts`}</inlineCode></p>
        <pre><code parentName="pre" {...{
            "className": "language-ts"
          }}>{`import * as cdk from 'aws-cdk-lib'
import { NodejsFunction } from 'aws-cdk-lib/aws-lambda-nodejs'
import * as lambda from 'aws-cdk-lib/aws-lambda'
import * as path from 'path'

export class CdkStack extends cdk.Stack {
  constructor(scope: cdk.App, id: string, props?: cdk.StackProps) {
    super(scope, id, props)

    new NodejsFunction(this, 'helloFn', {
      memorySize: 1024,
      timeout: cdk.Duration.seconds(5),
      runtime: lambda.Runtime.NODEJS_18_X,
      handler: 'main',
      entry: path.join(__dirname, \`../functions/hello.ts\`),
    })
  }
}
`}</code></pre>
        <p><inlineCode parentName="p">{`hello.ts`}</inlineCode></p>
        <pre><code parentName="pre" {...{
            "className": "language-ts"
          }}>{`import { APIGatewayProxyEventV2, APIGatewayProxyResultV2 } from 'aws-lambda'

export async function main(
  event: APIGatewayProxyEventV2
): Promise<APIGatewayProxyResultV2> {
  return {
    body: JSON.stringify({ message: 'Hello World 👋🏼' }),
    statusCode: 200,
  }
}
`}</code></pre>
        <h3 {...{
          "id": "complex-stack",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#complex-stack",
            "aria-label": "complex stack permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Complex stack`}</h3>
        <p><a parentName="p" {...{
            "href": "https://github.com/aws-samples/aws-cdk-examples/blob/master/typescript/api-cors-lambda-crud-dynamodb/index.ts"
          }}>{`Stack with API Gateway, Lambda Function, and DynamoDB`}</a></p>
        <h3 {...{
          "id": "resources",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#resources",
            "aria-label": "resources permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Resources`}</h3>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/cdk/v2/guide/getting_started.html#getting_started_concepts"
            }}>{`AWS CDK — Getting Started`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.tinystacks.com/blog-post/aws-cdk-vs-cloudformation/"
            }}>{`CloudFormation vs CDK`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://bobbyhadz.com/blog/aws-cdk-http-api-apigateway-v2-example"
            }}>{`API Gateway example`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://bobbyhadz.com/blog/aws-cdk-typescript-lambda"
            }}>{`Lambda Function Example`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/aws-samples/aws-cdk-examples/tree/master/typescript"
            }}>{`TypeScript examples`}</a></li>
        </ul>
      </MarkdownWrapper>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      